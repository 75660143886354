import React from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp';
import COLORS from "../constants/colors";

const emailCopy = {
  title: 'Subscribe to my mailing list',
  rider: 'I\'ll only reach out with a monthly roundup of posts. Opt out anytime.'
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  background: #f2f2f2;

  padding: 2rem;

  h2 {
    margin-top: 0;
    margin-bottom: .1rem;
    color: ${COLORS.baseColor};
  }

  .email-rider {
    font-size: 90%;
    margin: 0;
    margin-bottom: 1rem;
    opacity: 0.65;
  }

  .Wrapper {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 570px) {
      flex-direction: column;
    }
  }

  input {
    color: #2a2a2a;
    width: 100%;
    border: none;
    margin-right: 16px;

    @media only screen and (max-width: 570px) {
      margin: 0;
      margin-bottom: 16px;
    }
  }

  button,
    input {
    padding: 1rem 1.5rem;
  }

  button {
    display: inline - block;

    border: none;
    background-image: none;
    background-color: #DD0505;
    color: white;

    letter-spacing: 1px;
    transition: all 0.1s linear;

    &:hover {
      cursor: pointer;
      background: #D70404;
    }
  }
`


const INITIAL_FORM_STATE = {
  email: ''
}

const EmailListSignUp = () => {
  const [form, setForm] = React.useState(INITIAL_FORM_STATE)


  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(form.email)
      .then((data) => {
        alert("Thank you. You've been added to the mailing list!");
        setForm(INITIAL_FORM_STATE)
      })
  }

  const handleChange = (fieldName) => (e) => {
    setForm({ ...form, [fieldName]: e.target.value })
  }

  return (
    <div>
      <StyledForm onSubmit={handleSubmit}>
        <h2>{emailCopy.title}</h2>
        <p className="email-rider">{emailCopy.rider}</p>
        <div className="Wrapper">
          {/* <input placeholder="First name" onChange={handleChange("firstName")} /> */}
          <input type="email" placeholder="Email address" onChange={handleChange("email")} />
          <button type="submit">Subscribe</button>
        </div>
      </StyledForm>
    </div>
  )
}

export default EmailListSignUp;